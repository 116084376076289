// src/templates/pages/PressReleaseListing.js

import React from 'react'
import { graphql, Link } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import parse from 'html-react-parser'
import Layout from '../../components/Layout.js'
import ResourcesHeader from '../../components/ResourcesHeader'
import Pagination from '../../components/Pagination.js'

function PressReleaseListing(props) {
  const listing = props.data.allWpPressRelease

  const seoData = {
    seo: {
      title: 'Press Releases - Arete',
    },
  }

  return (
    <Layout>
      <Seo post={seoData} />

      <ResourcesHeader title="Press Releases" />

      <section className="press-listing">
        <div className="container">
          <ul className="divide-y divide-granite-200">
            {listing.edges.map((node, index) => {
              return (
                <li key={index} className="py-responsive-xl-32">
                  <Link to={node.node.uri} className="no-underline">
                    {node.node.title && (
                      <h2 className="headline-sm mb-responsive-xl-16">
                        {parse(node.node.title)}
                      </h2>
                    )}

                    <p className="text-nav uppercase text-boynton-a11y">
                      {node.node.date}
                    </p>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      <section className="press-paginate">
        <div className="container">
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
            pageSlug="press-releases"
          />
        </div>
      </section>
    </Layout>
  )
}

export default PressReleaseListing

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpPressRelease(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          uri
        }
      }
    }
  }
`
